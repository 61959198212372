<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

/**
 * Starter page component
 */
export default {
  page() {
    return {
      title: this.title,
      meta: [{ name: "description" }],
    };
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "",
      items: [],
      loading: true,
      messagelist: [],
      page: 1,
      userinfo: [],
      limit: 20,
      messagetotal: 0,
      notifysetting: {},
      notifytype: "wechat",
    };
  },
  mounted() {
    this.title = this.$t("menuitems.clouds.notify.text");
    this.items = [
      {
        text: this.$t("menuitems.clouds.text"),
        href: "/",
      },
      {
        text: this.$t("menuitems.clouds.notify.text"),
        active: true,
      },
    ];
    this.userinfo = JSON.parse(localStorage.getItem("user"));
    //   this.page.title=this.$t('global.notice.type.notify')
    this.getnotifysetting();
  },
  methods: {
    getnotifysetting() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "getnotifysetting",
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.notifysetting = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    savenotifysetting() {
      var that = this;
      that.loading = true;
      that.$axios
        .post(
          that.apiuri,
          {
            action: "savenotifysetting",
            data: that.notifysetting
          },
          {
            headers: { Openid: that.userinfo.openid },
          }
        )
        .then(function (response) {
          that.loading = false;
          that.getnotifysetting();
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    subscribeUser() {
      navigator.serviceWorker.ready.then(registration => {
        const vapidPublicKey = 'YOUR_PUBLIC_VAPID_KEY';
        const convertedVapidKey = this.urlBase64ToUint8Array(vapidPublicKey);

        registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey
        }).then(subscription => {
          console.log('User is subscribed:', subscription);
          // 发送订阅对象到服务器保存
          this.sendSubscriptionToServer(subscription);
        }).catch(error => {
          console.error('Failed to subscribe the user:', error);
        });
      });
    },
    urlBase64ToUint8Array(base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4);
      const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    },
    sendSubscriptionToServer(subscription) {
      fetch('/api/save-subscription', {
        method: 'POST',
        body: JSON.stringify(subscription),
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(response => {
        if (!response.ok) {
          throw new Error('Failed to save subscription');
        }
        return response.json();
      }).then(data => {
        console.log('Subscription saved:', data);
      }).catch(error => {
        console.error('Error saving subscription:', error);
      });
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body" v-loading="loading">
            <el-tabs v-model="notifytype" @tab-click="handleClick">
              <el-tab-pane :label="$t('clouds.notify.wechat')" name="wechat">
                <el-form ref="form" :model="notifysetting" label-width="80px">
                  <el-form-item :label="$t('clouds.notify.enable')">
                    <el-switch :active-value="1" :inactive-value="0" v-model="notifysetting.status"></el-switch>
                  </el-form-item>
                  <el-form-item :label="$t('clouds.notify.wechatgrouop')">
                    <el-input v-model="notifysetting.id"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="savenotifysetting">{{ $t("device.hd.save") }}</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane :label="$t('clouds.notify.email')" name="email">
                <el-alert :title="$t('clouds.notify.emailalert')" type="warning" :closable="false">
                </el-alert>
                <el-form ref="form" :model="notifysetting" label-width="80px">
                  <el-form-item :label="$t('clouds.notify.enable')">
                    <el-switch :active-value="1" :inactive-value="0" v-model="notifysetting.status"></el-switch>
                  </el-form-item>
                  <el-form-item :label="$t('clouds.notify.emailaddress')">
                    <el-input v-model="notifysetting.id"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="savenotifysetting">{{ $t("device.hd.save") }}</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane :label="$t('clouds.notify.sms')" name="sms" disabled>
                <el-form ref="form" :model="notifysetting" label-width="80px">
                  <el-form-item :label="$t('clouds.notify.enable')">
                    <el-switch :active-value="1" :inactive-value="0" v-model="notifysetting.status"></el-switch>
                  </el-form-item>
                  <el-form-item :label="$t('clouds.notify.phonenumer')">
                    <el-input v-model="notifysetting.phone"></el-input>
                  </el-form-item>
                  <el-form-item>
                    <el-button type="primary" @click="savenotifysetting">{{ $t("device.hd.save") }}</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>
              <el-tab-pane :label="$t('clouds.notify.browser')" name="browser" disabled>
                <el-form ref="form" label-width="80px">
                  <el-form-item>
                    <el-button type="primary" @click="subscribeUser">{{ $t("clouds.notify.browserregister")
                      }}</el-button>
                  </el-form-item>
                </el-form>
              </el-tab-pane>

            </el-tabs>

          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>